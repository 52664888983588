import $ from "jQuery";
import http from "http";


let last_id;
   
const bgadefault =
  "rgba(59.84307012361466, 121.82006430460443, 27.1279410154659, 0.2)";
const bgb =
  "rgba(105.99907993018873, 76.49164810815056, 6.820820253304677, 0.1)";

const bg_c =
  "rgba(117.44529566056224, 58.44939534617633, 203.2792277143402, 0.2)";
// 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
// Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
// This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
const targetElement = document.querySelector("body");
targetElement.style.background = bgb;
let toggler = 0;
// 4. ...in some event handler after hiding the target element...

// document.addEventListener("DOMContentLoaded", function (event) {
//   let img = document.querySelector(".circle");
//   img.addEventListener("click", function (el) {
//     if (toggler) {
//       targetElement.style.background = bgb;
//     } else {
//       targetElement.style.background = randomcolor();
//     }
//     toggler = !toggler;
//   });
// });

function randomcolor() {
  let a, b, c;
  a = Math.random() * 255;
  b = Math.random() * 255;
  c = Math.random() * 255;
  let color = `rgba(${a}, ${b}, ${c}, 0.2)`;
  console.log(color);
  return color;
}

var $objHead = $("head");

// define a function to disable zooming
var zoomDisable = function () {
  $objHead.find("meta[name=viewport]").remove();
  $objHead.prepend(
    '<meta name="viewport"content="width=device-width, initial-scale=1.0, user-scalable=0" />'
  );
};

// ... and another to re-enable it
var zoomEnable = function () {
  $objHead.find("meta[name=viewport]").remove();
  $objHead.prepend(
    '<meta name="viewport"content="width=device-width, initial-scale=1.0, user-scalable=1" />'
  );
};

// if the device is an iProduct, apply the fix whenever the users touches an input
// if (
//   navigator.userAgent.length &&
//   /iPhone|iPad|iPod/i.test(navigator.userAgent)
// ) {
  // define as many target fields as your like
//   $("img")
//     .on({
//       touchstart: function () {
//         zoomDisable();
//       },
//     })
//     .on({
//       touchend: function () {
//         setTimeout(zoomEnable, 500);
//       },
//     });
// }



// 3. ...in some event handler after showing the target element...disable body scroll

// 4. ...in some event handler after hiding the target element...

// function freshDot() {
//     this.obj = document.createElement("div");
//     this.obj.classList.add("box");
//     this.obj.style.top = (window.innerHeight * Math.random()) + 'px';
//     this.obj.style.left = (window.innerWidth * Math.random()) + 'px';
//     this.size = Math.floor(5 * Math.random()) + 4;
//     this.obj.style.height = this.size + 'px';
//     this.obj.style.width = this.size + 'px';

//     document.body.appendChild(this.obj);
// }
// var dot = [];
// for (var i = 0; i < 250; i++) {
//     var delayInMilliseconds = 100; //1 second

//     setTimeout(function () {
//         dot.push(new freshDot());
//         //your code to be executed after 1 second
//     }, delayInMilliseconds);

// }
/*
    $(window).resize(function(){
      for(i=0;i<200;i++){
        document.body.removeChild(dot[i]);
      }
    });
    */ 
   const e = document.querySelector(".event_log")
    
    const poll = {
        pollB: function() {
            http.get('http://45.79.249.196:1234', (res) => {
                const { statusCode } = res;
    
                let error;
                if (statusCode !== 200) {
                    error = new Error(`Request Failed.\n` +
                        `Status Code: ${statusCode}`);
                }
    
                if (error) {
                    console.error(error.message);
                    res.resume();
                } else {
                    res.setEncoding('utf8');
                    let rawData = '';
                    res.on('data', (chunk) => { rawData += chunk; });
                    res.on('end', () => {
                        try {
                          
                            const parsedData = JSON.parse(rawData);
                           
                            console.log(parsedData)
    
                            // The important logic comes here
                            if (parsedData.status === 'success') {
                                setTimeout(poll.pollB, 1000); // request again in 10 secs
                               insert_html(parsedData)

                            } else {
                              setTimeout(poll.pollB, 10000);
                                // Call the background process you need to
                            }
                        } catch (e) {
                            console.error(e.message);
                             setTimeout(poll.pollB, 10000);
                        }
                    });
                }
            }).on('error', (e) => {
                console.error(`Got error: ${e.message}`);
                insert_html(0)
                setTimeout(poll.pollB, 10000);
            });
        }
    }
    
    poll.pollB();
    
    // Initialize and add the map


    function insert_html(data){
        let pos ={
            x:0,
            y:0
        }
    if (data != 0){
        if(data.query !== last_id){
            e.innerHTML = "<p>Username: "+data.username+"</br>Ort: "+data.country+", "+data.city+"</br>Region: "+data.region+", "+data.timezone+"</br>ISP: "+data.isp+"</br>AS: "+data.as+"</br>IP-Adress: "+data.query+"</p>"
            pos.x = data.lon
            pos.y = data.lat
           
            draw(pos)

          }
        
    }
   else{
    e.innerHTML = e.innerHTML = "<p>reconnect to server</p>"
   }
    last_id = data.query
     
  

    }
    let canvas = document.querySelector("canvas");
    let context = canvas.getContext("2d");
    
    // get current size of the canvas
    let rect = canvas.getBoundingClientRect();
    
    // increase the actual size of our canvas
    canvas.width = rect.width * devicePixelRatio;
    canvas.height = rect.height * devicePixelRatio;
    
    // ensure all drawing operations are scaled
    context.scale(devicePixelRatio, devicePixelRatio);
    
    // scale everything down using CSS
    canvas.style.width = rect.width + 'px';
    canvas.style.height = rect.height + 'px';
    let counter = 0;
    function draw(pos) {
      const w = canvas.width/2
      const h = canvas.height/2
      console.log(w)
      // draw the colored region
      context.beginPath();
      context.arc(180+pos.x, 180-pos.y*2, 4, 0, 2 * Math.PI, true);
      context.fillStyle = "black";
      context.fill();
    
      // draw the stroke
      context.lineWidth = 2;
      context.strokeStyle = "black";
      context.stroke();
      if(counter++ > 10){context.clearRect(0, 0, canvas.width, canvas.height);
      counter =0 }
      
    }
    draw();


    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }